import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/fashionist-style/:id',
    name: 'FashinistStyle',
    component: () => import('../views/FashionStyle.vue')
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('../views/ShopView.vue')
  },
  {
    path: '/shop-details',
    name: 'ShopDetails',
    component: () => import('../views/ShopDetails.vue')
  },
  {
    path: '/shop-details-cart',
    name: 'ShopDetailsCart',
    component: () => import('../views/ShopDetailsCart.vue')
  },
  {
    path: '/shop-details-card-checkout',
    name: 'ShopDetailsCardCheckout',
    component: () => import('../views/ShopDetailsCheckout.vue')
  },
  {
    path: '/register-shop',
    name: 'RegisterShop',
    component: () => import('../views/RegisterShop.vue')
  },
  {
    path: '/fashionist',
    name: 'Fashionist',
    component: () => import('../views/FashionistView.vue')
  },
  {
    path: '/fashionist-gallery',
    name: 'FashionistGallery',
    component: () => import('../views/GalleryView.vue')
  },
  {
    path: '/register-fashionist',
    name: 'RegisterFashionist',
    component: () => import('../views/RegisterFashionist.vue')
  },
  {
    path: '/upload-gallery',
    name: 'UploadFashionistGallery',
    component: () => import('../views/UploadFashionistGallery.vue')
  },
  {
    path: '/login-fashionist',
    name: 'LoginFashionist',
    component: () => import('../views/LoginFashionist.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('../views/ServiceView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

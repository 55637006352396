<template>
  <div class="nav-main">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand font-weight-bold" href="/">Fashionist</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link" href="/">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/about">About</a>
                        </li>
                        <li class="nav-item">
                            <!-- <a class="nav-link" href="/fashionist-gallery">Gallery</a> -->
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/register-fashionist">Register Fashionist</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/upload-gallery" v-if="uploadGallery">Upload To Gallery</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="/login-fashionist" v-if="fashionistBoolean">Login</a>
                            <a class="nav-link" href="#" @click="logout();" v-if="fashionistBooleanLogout">Logout</a>
                        </li>
                    </ul>

                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <a class="nav-link" href="/contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </nav>
            <!--end:Nav -->
        </div>
    </div>
</template>
<script>
export default {
  data () {
    return {
      fashionistBoolean: true,
      fashionistBooleanLogout: false,
      uploadGallery: false
    }
  },
  methods: {
    requiredData () {
      const userID = localStorage.getItem('userID')
      if (userID != null) {
        this.fashionistBoolean = false
        this.uploadGallery = false
      }
      if (userID != null) {
        this.fashionistBooleanLogout = true
        this.uploadGallery = true
      }
    },
    logout () {
      localStorage.removeItem('userID')
      this.$router.push('/')
    }
  },
  mounted () {
    this.requiredData()
  }
}
</script>

<template>
  <div class="entry work-entry branding" @click="fashionistDetails();">
    <span hidden>{{ user_id }}</span>
    <a href="#">
      <img
        class="entry-image imageBG"
        :src="fashionistImage"
      />
      <div class="work-entry-hover">
        <div class="work-entry-content">
          <div class="work-entry-title">{{ fashionNames }}</div>
          <div class="work-entry-cat">{{ fashionType }}</div>
        </div>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  props: {
    user_id: {
      type: String,
      default: ''
    },
    fashionistImage: {
      type: String,
      default: ''
    },
    fashionNames: {
      type: String,
      default: 'Heritier Ganza Tamba'
    },
    fashionType: {
      type: String,
      default: 'Photographer'
    }
  },
  methods: {
    fashionistDetails () {
      this.$router.push('/fashionist-style/' + this.user_id)
    }
  }
}
</script>

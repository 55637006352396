<template>
  <!-- Navbar Start -->
  <HeaderItem />
    <!--end:Navigation -->
    <main role="main">
        <section class="intro">
            <div class="container">
                <div class="row d-flex  align-items-start">
                    <div class="col-md-11">
                        <h1 class="display-3">The Art of Imagination.<br>Fashionist Platform Based In kigali Rwanda.</h1>
                    </div>
                </div>
            </div>
        </section>
        <!--end:Intro -->
        <section class="space-md">
            <!-- portfolio -->
            <div id="portfolio" class="container">
                <div id="portfolio-filters">
                    <ul id="filters" class="p-0">
                        <li><a href="*" class="active">everything</a></li>
                        <li><a href=".digital">Digital</a></li>
                        <li><a href=".branding">Web Design</a></li>
                        <li><a href=".campaigns">Marketing</a></li>
                    </ul>
                </div>
                <div class="grid" data-cols="3" data-margin="0" data-height="1" data-masonry='{ "columnWidth": 200, "itemSelector": ".entry" }'>
                    <!-- entry -->
                    <FashionistItem
                    v-for="(fashion,key) in fashions"
                    :key="key"
                    :fashionNames="fashion.fashionistName"
                    :fashionType="fashion.fashionistCategory"
                    :fashionistImage="fashion.fashionistImage"
                    :user_id="fashion.user_id" />
                </div>
            </div>
            <!-- /end:portfolio -->
        </section>
    </main>
   <footer class="position-relative">
        <div class="container">
            <div class="row">

                <div class="col-lg-auto mt-4">
                    <p class="mb-4">fashionox.art</p>
                </div>
                <div class="col-lg-3 mt-4 ml-auto">
                    <p>Kicukiro, Kigali Rwanda
                        <br> +250781065367
                        <br> +250726201364
                        <br> fbih
                    </p>
                        <a href="mailto:fashionox.inc@gmail.com"><span style="text-decoration: underline;">fashionox.inc@gmail.com</span></a>
                </div>
                <div class="col-lg-3 mt-4 ml-auto">
                    <ul class="list-unstyled footer-link">
                        <li><a href="#">instagram</a></li>
                        <li><a href="#">facebook</a></li>
                        <li><a href="#">twitter</a></li>
                    </ul>
                </div>
                <div class="col-lg-auto ml-lg-auto mt-4">
                    <ul class="list-unstyled footer-link">
                        <li><a href="#">instagram</a></li>
                        <li><a href="#">facebook</a></li>
                        <li><a href="#">twitter</a></li>
                        <!-- Please note.You are not allowed to remove credit link.Please respect that.-->
                        <li><a href="https://fashionox.art/">dev by fashionox inc</a></li>
                    </ul>
                </div>
            </div>
            <!--/.row-->
        </div>
        <!--/.container-->
    </footer>
</template>
<script>
import axios from 'axios'
import FashionistItem from '../components/FashionistItem.vue'
import HeaderItem from '../components/HeaderItem.vue'
export default {
  components: {
    FashionistItem,
    HeaderItem
  },
  data () {
    return {
      fashions: [
        {},
        {},
        {}
      ]
    }
  },
  methods: {
    async retreiveFashionist () {
      try {
        const response = await axios.get(
          '/api/list-fashionist'
        )
        this.fashions = response.data
        console.log(this.fashions)
      } catch (error) {
        alert(error.message)
      }
    }
  },
  mounted () {
    this.retreiveFashionist()
  }
}
</script>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// const baseURL = 'https://back.fashionox.art'
// console.log(baseURL)
axios.defaults.baseURL = 'https://back.fashionox.art'
axios.defaults.headers.post['Content-Type'] = 'application/json'

createApp(App).use(store).use(router).mount('#app')
